import {Container} from "react-bootstrap";
import Headline from "../components/Headline";
import React from "react";
import {Helmet} from "react-helmet";

export default function Imprint() {
    return (
        <div>
            <Helmet>
                <title>Impressum - Hausmeisterservice Bujadilo</title>
            </Helmet>
            <Headline headline={"Hausmeisterservice Bujadilo"}
                      headline2={"Ihre professionelle Unterstützung Ihrer Objekte in Gröbenzell, Puchheim, Fürstenfeldbruck und Umgebung!"}
                      paragraph={""} bg={"bg-white"}/>
            <Container>
                <h1>Impressum</h1>
                <h2>Angaben gemäß § 5 TMG</h2>
                <p>Severin Bujadilo <br/>
                    Bujadilo Hausmeisterservice <br/>
                    Hubertusstraße 13 <br/>
                    82194 Gröbenzell</p><h2>Kontakt</h2>
                <p>Telefon: +49 175 9090713 <br/>E-Mail: hms-bujadilo.de</p><h2>EU-Streitschlichtung</h2><p>Die
                Europäische
                Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                    href="https://ec.europa.eu/consumers/odr/" rel="noopener noreferrer"
                    target="_blank">https://ec.europa.eu/consumers/odr/</a>.<br/>Unsere E-Mail-Adresse finden Sie oben
                im
                Impressum.</p><h2>Verbraucher­streit­beilegung/Universal­schlichtungs­stelle</h2><p>Wir sind nicht
                bereit
                oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
                teilzunehmen.</p>
                <p>Quelle: <a href="https://www.e-recht24.de">https://www.e-recht24.de</a></p></Container>
        </div>
    );
}