import {Carousel, Container} from "react-bootstrap";
import "../styles/home.css";
import React from "react";
import Headline from "../components/Headline";
import {Helmet} from "react-helmet";

export default function Home() {
    return (
        <div>
            <Helmet>
                <title>Ihre verlässlichen Partner für alle Ihre Hausmeisterbedürfnisse - Hausmeisterservice
                    Bujadilo</title>
            </Helmet>
            <Headline headline={"Hausmeisterservice Bujadilo"}
                      headline2={"Ihre professionelle Unterstützung Ihrer Objekte in Gröbenzell, Puchheim, Fürstenfeldbruck und Umgebung!"}
                      paragraph={""} bg={"bg-dark"}/>

            <div className={"bg-dark text-white"}>
                <Container className={"fs-5"}>
                    <div className={"h1"}>Über uns:</div>
                    <div className={"home-container"}>
                        <div className={"fs-5 home-text"}>Willkommen beim Hausmeisterservice Bujadilo! Wir sind Ihre verlässlichen Partner für alle Ihre Hausmeisterbedürfnisse. Mit langjähriger Erfahrung bieten wir professionelle und zuverlässige Dienstleistungen für Privathaushalte, Unternehmen und Immobilienverwaltungen. Unser engagiertes Team steht Ihnen mit einem breiten Spektrum an Leistungen zur Verfügung, darunter Gebäudereinigung, Gartenpflege, Winterdienst, Reparaturen und vieles mehr. Wir legen Wert auf hohe Qualität, pünktliche Ausführung und faire Preise. Vertrauen Sie auf unsere Expertise, um Ihre Immobilie in bestem Zustand zu halten. Kontaktieren Sie uns noch heute und lassen Sie uns gemeinsam eine maßgeschneiderte Lösung für Ihre Anforderungen entwickeln!
                        </div>
                            <img className={"home-image"} alt={"Geschäftsführer"}
                                 src={"/assets/Geschäftsführer.jpg"}/>
                    </div>
                </Container>
            </div>

            <Container className={"fs-5 about"}>
                <div className={"h1"}>Warum Wir?</div>
                <div className={"why-description"}>
                    <p>Sie suchen einen zuverlässigen und erfahrenen Hausmeisterservice, der Ihnen alle Aufgaben rund um
                        Haus, Garten, Gewerbeflächen oder Parkplätzen abnimmt?</p>
                    <p><b>Dann sind Sie bei uns genau richtig!</b> Wir bieten Ihnen ein breites Leistungsspektrum, von
                        der
                        Reinigung und Instandhaltung bis hin zu Winterdienst und Gartenpflege.</p>
                    <p>Wir sind pünktlich, ordentlich, vertrauenswürdig und verfügen über langjährige
                        Erfahrung und das erforderliche Fachwissen, um alle Arbeiten fachgerecht und zuverlässig
                        auszuführen.</p>
                </div>
                <div className={"why-container"}>
                    <div className={"why-carousel"}>
                        <Carousel>
                            <Carousel.Item>
                                <img alt={"Rasenmäher"} className={"why-image"}
                                     src={"/assets/lawn-mower-938555_1920.jpg"}/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt={"Handschuhe und Reinigungsmittel"} className={"why-image"}
                                     src={"/assets/gloves-4017614_1920.jpg"}/>
                            </Carousel.Item>
                            <Carousel.Item>
                                <img alt={"Heckenschere"} className={"why-image"}
                                     src={"/assets/hedge-3393849_1920.jpg"}/>
                            </Carousel.Item>
                        </Carousel>
                    </div>
                    <div className={"why-text fs-5"}>
                        <dl>
                            <dt>Erstklassiger Service:</dt>
                            <dd>Wir gehen auf Ihre individuellen Bedürfnisse ein und bieten Ihnen
                                maßgeschneiderte Lösungen.
                            </dd>

                            <dt>Breites Leistungsspektrum:</dt>
                            <dd>Wir bieten alle Dienstleistungen rund um Haus, Garten, Gewerbeflächen und Parkplätzen
                                an,
                                von der Reinigung und Instandhaltung bis hin zu Winterdienst und Gartenpflege.
                            </dd>

                            <dt>Transparente Preisgestaltung:</dt>
                            <dd>Keine versteckten Kosten, sondern faire Preise für alle
                                Leistungen.
                            </dd>

                            <dt>Offene Kommunikation:</dt>
                            <dd>Wir halten Sie stets über alle Arbeiten auf dem Laufenden.</dd>
                        </dl>
                    </div>
                </div>

            </Container>
        </div>
    );
}