import {Link} from "react-router-dom";
import "../styles/footer.css";

export default function Footer() {
    return (
        <div className="footer-height page-footer font-small blue pt-4">
            <div className="container-fluid text-center text-md-left">
                <div className="row">
                    <hr/>
                    <div className="col5">
                        <img className={"footer-image"} alt={"Logo"} src={"/assets/Logo.png"}/>
                        <h5 className="text-uppercase">Hausmeisterservice Bujadilo</h5>
                        <div className="row mb-md-0 mb-5">
                            <Link to={"/"} className={"nav-link"}>Home</Link>
                            <Link to={"/unsere-dienste"} className={"nav-link"}>Unsere Dienste</Link>
                            <Link to={"/kontakt"} className={"nav-link"}>Kontakt</Link>
                            <Link to={"/impressum"} className={"nav-link"}>Impressum</Link>
                            <Link to={"/datenschutzerklaerung"} className={"nav-link"}>Datenschutzerklärung</Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright text-center py-3">© 2024 Copyright: <a href="https://hms-bujadilo.de/">hms-bujadilo.de</a>
            </div>
        </div>
    );
}