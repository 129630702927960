import {NavLink} from "react-bootstrap";
import {Link} from "react-router-dom";
import React from "react";
import {Helmet} from "react-helmet";


export default function ErrorPage() {
    return (
        <div id="error-page">
            <Helmet>
                <title>Ihre verlässlichen Partner für alle Ihre Hausmeisterbedürfnisse - Hausmeisterservice Bujadilo</title>
            </Helmet>
            <h1>Oops!</h1>
            <p>Sorry, an unexpected error has occurred.</p>
            <NavLink><Link to={"/"} className={"nav-link"}>Back to the Homepage</Link></NavLink>
        </div>
    );
}