import Headline from "../components/Headline";
import {Button, Card, Container, Table} from "react-bootstrap";
import "../styles/contact.css"

import React from "react";
import {Helmet} from "react-helmet";
export default function Contact() {
    return (
        <div>
            <Helmet>
                <title>Kontakt - Hausmeisterservice Bujadilo</title>
            </Helmet>
            <Headline headline={"Hausmeisterservice Bujadilo"}
                      headline2={"Ihre professionelle Unterstützung Ihrer Objekte in Gröbenzell, Puchheim, Fürstenfeldbruck und Umgebung!"}
                      paragraph={"Haben Sie Fragen zu unseren Angebot oder möchten einen Termin vereinbaren? Kontaktieren Sie uns gerne!"}
                      bg={"bg-white"}/>
            <Container>
                <div className={"h1"}>Ihr Kontakt zu uns</div>
                <div className={"contact-flex-container"}>
                    <div className={"fs-5 contact-text-left"}>
                        <p>Kontaktieren Sie uns noch heute, und lassen Sie uns gemeinsam daran arbeiten, Ihre Ziele zu
                            erreichen.</p>
                        <p>Sie können uns telefonisch erreichen oder schreiben Sie uns eine E-Mail.</p>
                        <p>Wir werden uns bei Ihnen melden, um Ihre Fragen zu beantworten und Ihnen weiterzuhelfen.</p>
                        <Button href="mailto: info@hms-bujadilo.de" className={"e-mail-button"}>Schreiben Sie uns eine E-Mail!</Button>
                    </div>
                    <div className={"fs-5 contact-card"}>
                        <Card className={"contact-card"}>
                            <Card.Header className={"contact-card-header text-white"}>
                                <b>Schneller Kontakt 📞</b>
                            </Card.Header>
                            <Card.Body>
                                <Card.Text>
                                    <Table borderless={true}>
                                        <thead>
                                        <tr>
                                            <td><b>Öffnungszeiten:</b></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td>Montag - Freitag</td>
                                            <td>8:00 - 18:00 Uhr</td>
                                        </tr>
                                        <tr>
                                            <td>Samstag</td>
                                            <td>10:00 - 16:00 Uhr</td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                    <Table borderless={true}>
                                        <thead>
                                        <tr>
                                            <td><b>Telefonnummer:</b></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><a href="tel: +491759090713">+49 (0)175 9090713</a></td>
                                        </tr>
                                        </tbody>
                                        <thead>
                                        <tr>
                                            <td><b>E-Mail:</b></td>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <td><a href="mailto: info@hms-bujadilo.de">info@hms-bujadilo.de</a></td>
                                        </tr>
                                        </tbody>
                                    </Table>
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </div>
                </div>
            </Container>
        </div>
    );
}