import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import Root from "./routes/root";
import ErrorPage from "./error-page";
import Home from "./routes/home";
import Privacy from "./routes/privacy";
import Imprint from "./routes/imprint";
import Services from "./routes/services";
import Contact from "./routes/contact";



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/unsere-dienste",
                element: <Services />,
            },
            {

                path: "/kontakt",
                element: <Contact />,
            },
            {
                path: "/impressum",
                element: <Imprint />,
            },
            {
                path: "/datenschutzerklaerung",
                element: <Privacy />,
            },
            {
                path: "/privacy",
                element: <Privacy />,
            },
        ],
    },
]);
root.render(
  <React.StrictMode>
      <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
