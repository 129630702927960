import {NavLink , Outlet} from "react-router-dom";
import {Container, Nav, Navbar} from "react-bootstrap";
import Footer from "../components/Footer";
import "../styles/root.css"

function Root() {
    return (
        <div id={"page-container"}>
            <div id="content-wrap">
                <Navbar bg="dark" data-bs-theme="dark" expand="lg" collapseOnSelect={true}>
                    <Container>
                        <Navbar.Brand>
                            <Nav.Link as={NavLink} to="/" className={"nav-link"}><img alt={"Unternehmenslogo"}
                                                                     className="d-inline-block align-top"
                                                                     height="30" src={"/assets/Logo.png"}/> HMS - Bujadilo</Nav.Link>
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link as={NavLink} to={"/"} className={"nav-link"}>Home</Nav.Link>
                                <Nav.Link as={NavLink} to={"/unsere-dienste"} className={"nav-link"}>Unsere Dienste</Nav.Link>
                                <Nav.Link as={NavLink} to={"/kontakt"} className={"nav-link"}>Kontakt</Nav.Link>
                                <Nav.Link as={NavLink} to={"/impressum"} className={"nav-link"}>Impressum</Nav.Link>
                                <Nav.Link as={NavLink} to={"/datenschutzerklaerung"} className={"nav-link"}>Datenschutzerklärung</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
                <div id="detail">
                    <Outlet/>
                </div>
            </div>


            <div id="footer">
                <Footer/>
            </div>
        </div>
    );
}

export default Root;