import {Card, Container} from "react-bootstrap";
import "../styles/services.css"
import Headline from "../components/Headline";
import React from "react";
import {Helmet} from "react-helmet";

export default function Services() {
    return (
        <div className={"fs-5"}>
            <Helmet>
                <title>Grünanlagenpflege, Winterdienst, Gebäudereinigung und vieles mehr - Hausmeisterservice Bujadilo</title>
            </Helmet>
            <Headline headline={"Hausmeisterservice Bujadilo"}
                      headline2={"Ihre professionelle Unterstützung Ihrer Objekte in Gröbenzell, Puchheim, Fürstenfeldbruck und Umgebung!"}
                      paragraph={"Von der Reinigung und Pflege Ihrer Immobilie bis hin zu Gartenarbeiten und Winterdienst - wir kümmern uns um alles!"}
                      bg={"bg-white"}/>
            <Container>
                <div className={"services"}>
                    <div className={"h1"}>Gebäudemanagement</div>
                    <div className={"services-card-container"}>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Kleinreparaturen</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Unser Service deckt ein breites Spektrum an <b>Kleinreparaturen</b> ab,
                                            die <b>effizient und zuverlässig</b> von uns durchgeführt werden. Unser Ziel
                                            ist es, Ihnen einen
                                            umfassenden Service zu bieten, der alle Ihre Bedürfnisse im Bereich der
                                            Kleinreparaturen erfüllt.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Winterdienst</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Wir sind ausgerüstet und bereit, bei jedem Wetter auszurücken. Wir
                                            bieten <b>schnelle und effiziente Schneeräumung</b>, um sicherzustellen,
                                            dass Ihre Wege, Einfahrten und Parkplätze <b>sicher und frei von Schnee und
                                                Eis sind.</b>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Grünanlagenpflege</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Ein gut <b>gepflegter Außenbereich</b> bietet nicht nur eine angenehme
                                            Atmosphäre
                                            für
                                            Bewohner und Besucher, sondern erhöht auch den Wert Ihrer Immobilie. Wir
                                            sorgen wir für einen makellosen Garten: <b>Rasenmähen,
                                                Heckenschnitt und Unkrautbekämpfung für Ihr grünes Paradies.</b>
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Tonnendienst</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Unser Tonnendienst stellt sicher, dass Ihre <b>Abfall- und Papiertonnen
                                            rechtzeitig auf die Straße gebracht werden</b>, um geleert zu werden, und
                                            anschließend wieder an ihren Platz gebracht werden, was eine effiziente und
                                            stressfreie Abfallentsorgung gewährleistet.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Kehrarbeiten</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Kehrarbeiten sind ein wesentlicher Bestandteil
                                            der <b>Gebäudepflege.</b> Sie tragen dazu bei, die <b>Sauberkeit und
                                                Ästhetik</b> Ihrer Immobilie zu erhalten.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>

                <div>
                    <div className={"h1"}>Gebäudereinigung</div>
                    <div className={"services-card-container"}>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Fensterreinigung</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Wir reinigen Ihre Fenster für Sie, um sicherzustellen, dass Ihre
                                            Fenster <b>strahlend sauber und streifenfrei</b> sind.
                                            Ob Sie einen regelmäßigen Reinigungsservice oder eine einmalige
                                            Fensterreinigung
                                            benötigen, Wir sind bereit, Ihre Bedürfnisse zu
                                            erfüllen.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Treppenhausreinigung</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Wie bieten eine umfassende <b>Treppenhausreinigung</b>, die nicht nur
                                            für ein <b>sauberes und einladendes Umfeld</b> sorgt, sondern auch die
                                            Lebensdauer Ihrer Immobilie erhöht und den Wert Ihrer Immobilie
                                            steigert. Vertrauen Sie auf uns für eine zuverlässige, effiziente und
                                            fair bepreiste Treppenhausreinigung.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                        <div className={"services-card-text"}>
                            <Card className={"services-card"}>
                                <Card.Header className={"services-card-header text-white"}><b>Grundreinigung</b></Card.Header>
                                <Card.Body>
                                    <Card.Text>
                                        <div>Eine regelmäßige <b>Grundreinigung</b> sorgt nicht nur für ein <b>sauberes
                                            und hygienisches Umfeld</b>, sondern trägt auch zur Werterhaltung Ihrer
                                            Immobilie bei. Sie hilft, hartnäckige Verschmutzungen zu entfernen und
                                            das Erscheinungsbild Ihrer Räumlichkeiten zu verbessern.
                                        </div>
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </div>
                    </div>
                </div>
            </Container>
        </div>

    );
}