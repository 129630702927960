import {Container} from "react-bootstrap";
import "../styles/headline.css"
export default function Headline({ bg, headline, headline2, paragraph }: { headline: string, headline2: string, paragraph: string, bg: string}) {
    return (
        <div className={"headline-wrapper " + bg}>
            <div className={"headline-background"}>
                <Container className={"fs-5 text-white"}>
                    <div id="headline">
                        <div>
                            <h1 className={"h1 text-center"}>{headline}</h1>
                            <h2 className={"h2 text-center"}>{headline2}</h2>
                            <p className={"p headline-p text-center"}>{paragraph}</p>
                        </div>
                    </div>
                </Container>
            </div>
        </div>
    );
}